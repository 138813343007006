import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
      <div className="center-all">
      <div className="text-white">
      <h2>Landing zone</h2>
      <p>Something should be here, but I am too lazy to put here anything but this text</p>
      
      </div>
    </div>
    );
  }
}
 
export default Home;