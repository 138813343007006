import React from 'react';
import PropTypes from 'prop-types';
import FleurStyles from './Fleur.module.css';
import AliceCarousel from 'react-alice-carousel';
import Event from "./Event/Event"
import Promotion from "./Promotion/Promotion"
import Calendar from './Calendar/Calendar';

Fleur.propTypes = {};

Fleur.defaultProps = {};

function Fleur() {

  const handleDragStart = (e) => e.preventDefault();

  let items = [];
  for (let i = 1; i <= 16; i++) {
    items.push(<Event eventName={"Title " + i} onDragStart={handleDragStart} role="presentation" />)
  }
  console.log(items)

  function randomDate(date1, date2) {
    function randomValueBetween(min, max) {
      return Math.random() * (max - min) + min;
    }
    var date1 = date1 || '01-01-2022'
    var date2 = date2 || new Date().toLocaleDateString()
    date1 = new Date(date1).getTime()
    date2 = new Date(date2).getTime()
    if (date1 > date2) {
      return new Date(randomValueBetween(date2, date1)).yyyymmdd()
    } else {
      return new Date(randomValueBetween(date1, date2)).yyyymmdd()

    }
  }
  Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
  };


  let eventList = [];
  for (let i = 1; i <= 300; i++) {
    eventList.push({ title: "title " + i, date: randomDate('01/01/2022', '01/01/2023'), backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16) }) //All strings
  }
  console.log(eventList)

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1096: { items: 3 },
    1582: { items: 4 },
  };



  
  return (
    <div class="text-primary bg-dark">
      <div class="container my-5 col-md-6">
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="5000">
              <div class="container row">
                <Promotion promotionName='This is so COOL!' />
              </div>
            </div>
            <div class="carousel-item">
              <div class="container row">
                <Promotion promotionName='This is even better!' />
              </div>
            </div>
            <div class="carousel-item">
              <div class="container row">
                <Promotion promotionName='Nah, I dont want this' />
              </div>
            </div>
          </div>

        </div>
      </div>

      <AliceCarousel mouseTracking responsive={responsive} items={items} disableDotsControls={true} disableButtonsControls={true} infinite={true} />

      <div class="col-md-9" style={{ float: "right", padding: "3%", paddingLeft: "7%" }}>
        <Calendar events={eventList} />
      </div>

    </div>

  );
}

/*
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
*/
export default Fleur;
