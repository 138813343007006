import React from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import styles from './Egypt.module.css';

import Doggy from "../Doggy/Doggy.js"

Egypt.propTypes = {};

Egypt.defaultProps = {};

function Egypt(){
  return (
    <div className="center-all">
      <div className="text-white">
      <h2>Egypt</h2>
      <p>Something that should be on egypt site.</p>
      <div className='rowC'>
        <Doggy/><Doggy/><Doggy/><Doggy/><Doggy/><Doggy/><Doggy/><Doggy/><Doggy/><Doggy/><Doggy/>
      </div>
      
      </div>
    </div>
  );
}

export default Egypt;

