import React from 'react';
import PropTypes from 'prop-types';
import styles from './Promotion.module.css';


Promotion.defaultProps = {
  promotionName: "Cool  Promotion",
  description: "Even cooler description of that Promotion",
  info: "Info Title",
  moreInfo: "Some more info about something I don't care about",
  img: "https://media.discordapp.net/attachments/642794148881104899/995237448579825775/unknown.png",
  tag:"TAGS"
};

function Promotion({ promotionName, description, info, moreInfo, img, tag}) {
  let event = "card text-info bg-dark col-md-6"
  return (<div style={{ padding: "3%", paddingBottom: "15%", paddingRight:"7%" }}>
    <div class={event}>
      <div>
        <img src={img} class="img-fluid col-md-12" />
        <div class="col-md-2 h4" style={{background: "gray", position: "absolute", zIndex: "1", left: "80%", right: "0%",top: "5%",padding:"3%", borderRadius:"20px"}}>{tag}</div>
        <div style={{ position: "absolute", zIndex: "1", left: "0%", right: "0%",top: "60%" }}>
          <div class="h1">{promotionName}</div>
          <div class="h3">{description}</div>
        </div>
        <div class="col-md-6 row-md-1 h5" style={{ background: "gray", position: "absolute", zIndex: "1", left: "60%", right: "0%", top:"85%", paddingTop:"20px", paddingBottom: "20px" }}>
          <b>{info}</b><br />
          <span class="h6">{moreInfo}</span>
        </div>

      </div>
    </div>
  </div>

  );
}


export default Promotion;
