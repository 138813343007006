import React from 'react';
import PropTypes from 'prop-types';
import styles from './Event.module.css';


Event.defaultProps = {
  eventName: "Cool  Title",
  description: "Even cooler description of that Title",
  info: "Info Title",
  moreInfo: "Some more info about something I don't care about",
  img: "https://cdn.discordapp.com/attachments/642794148881104899/1005216068882673684/unknown.png"
};

function Event({ eventName, description, info, moreInfo, img, }) {
  let event = "card text-info bg-dark"
  return (<div style={{paddingBottom: "10%", paddingRight:"8%", paddingLeft:"3%" }}>
    <div class={event}>
      <div class="spacing-8">
        <img src={img} class="img-fluid" />
        <div style={{ position: "absolute", zIndex: "1", left: "0%", right: "0%",top: "35%" }}>
          <div class="h2">{eventName}</div>
          <div class="h5">{description}</div>
        </div>
        <div class="col-md-6 row-md-1 h6" style={{ background: "gray", position: "absolute", zIndex: "1", left: "0%", right: "0%", transform: "translate(120%, -50%)", }}>
          <b>{info}</b><br />
          <span class="small">{moreInfo}</span>
        </div>

      </div>
    </div>
  </div>

  );
}


export default Event;
