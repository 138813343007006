import React from 'react';
import PropTypes from 'prop-types';
import styles from './UnderHead.module.css';


UnderHead.propTypes = {};

UnderHead.defaultProps = {};


function UnderHead()
{
    return (<div className="center-all">
<div className="text-white">
              <h3>Yamete.moe</h3>
      <img src="https://cdn.discordapp.com/attachments/642794148881104899/948521513487851590/jahysmugPhotoscalettax4.000000.png" className="App-logo" alt="logo" />
      <p>
        URL proudly claimed by karinesX#9999
      </p>
            </div>
    </div>
      
    );
  }

  
 

export default UnderHead;
