import React, { Component } from 'react';
import './App.css';
import firebase from "firebase/app";
import {
  Route,
  NavLink,
  HashRouter,
  Routes
} from "react-router-dom";
import Home from "./Home";
import Recipe from "./components/Recipe/Recipe.js";
import Egypt from './components/Egypt/Egypt';
import UnderHead from './components/UnderHead/UnderHead';
import YAER from './components/YAER/YAER';
import Fleur from './components/Fleur/Fleur';

class App extends Component {
  render() {
    return (
      <div>
      <HashRouter>
        <div>
            <NavLink to="/"><div className='NavLink' >Home </div></NavLink>
            <NavLink to="/Recipe"><div className='NavLink' >Yamete </div></NavLink>
            <NavLink to="/Egypt"><div className='NavLink' >Egypt </div></NavLink>
            <NavLink to="/YAER"><div className='NavLink' >YAER </div></NavLink>
            <br/>
            <UnderHead />
          <div className="content">
            <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/Recipe" element={<Recipe/>}/>
            <Route path="/Egypt" element={<Egypt/>}/>
            <Route path="/YAER" element={<YAER/>}/>
            <Route path="/Fleur" element={<Fleur/>}/>
            </Routes>
          </div>
        </div>
        </HashRouter>
      </div>
    );
  }
}

export default App;
