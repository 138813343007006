import React from 'react';
import PropTypes from 'prop-types';
import './Recipe.module.css';


Recipe.propTypes = {};

Recipe.defaultProps = {};
function Recipe(){
  return (
      <div className="center-all">
    <header className="text-white">
          <h4>Curry Pasta recipe:</h4>
          <table>
            <tbody>
          <tr>
          <th className="Image-big">
            <img className="Image-big" src="https://cdn.discordapp.com/attachments/862656611130146826/947945269419470868/IMG_20220228_202443.jpg" alt="Curry Pasta"/>
          </th>
          <th style={{}}>
            Ingredients:
            <table>
              <tr>Your favourite pasta</tr>
              <tr>2 big onions</tr>
              <tr>1 pack of frozen soup vegetables</tr>
              <tr>some oil</tr>
              <tr>Optional: meat/liver</tr>
              <tr>Spices: Curry, garlic, salt</tr>
            </table>
          </th>
          </tr>
          </tbody>
      </table>
      <div className="center-all">
            Put some oil on a pan and let it heat up. Cut onions to small pieces and then let them fry until they are soft and or start turning brown. <br />
            Add frozen vegetable and as much garlic as you want and let it heat up until you feel the vegetable is cooked enough. Frequent mixing recomended! <br />
            If you have meat now it is a good time to drop it into the mix.<br />
            After it has changed color add hot water to the mix. After it starts boiling add curry and let it boil for 10-20 minutes.<br />
            In the mean time you can boil your pasta.
      </div>
    </header>
  </div>
  );
}

export default Recipe;
