import React from 'react';
import PropTypes from 'prop-types';
import styles from './YAER.module.css';


function YAER(){
  return (
    <div className={styles.YAER}>
    <div className="center-all">
      <div className="text-white">
      <h2>YAER Marketing Materials</h2>

<div className='rowC'>

<div className='vertical'>
      <a
        className="App-link"
        href="https://play.google.com/store/apps/details?id=com.revival.Endlessrunner"
        target="_blank"
        rel="noopener noreferrer"
      >
        Android Version
      </a>
      <div className='YAER'>
      <img className='img-QR' src="https://cdn.discordapp.com/attachments/942837384855306280/968210650151153755/Android.png"/>
      </div>
      </div>

<div className='vertical'> 
      <a
        className="App-link"
        href="https://apps.apple.com/sk/app/yet-another-endless-runner/id1576318900"
        target="_blank"
        rel="noopener noreferrer"
      >
        iOS Version
      </a>
      <div className='YAER'>
      <img className='img-QR' src="https://media.discordapp.net/attachments/942837384855306280/968210650461515776/iOS.png"/>
      </div>
      </div>  
</div>
      




      <p>Clean Images</p>
      
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973852432071262218/yaer_rvlabs_banner_iPhone.png"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973860252594360330/FHD_Desktop.png"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973860252879577148/iPhone_8_Plus.png"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973860253198319636/iPad_12.9_Landscape.png"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973860253512896542/iPad_12.9_Portrait.png"/>
      </div>
      
     
      
      
      <p>Others</p>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973855714567127080/iPhone_transparent.png"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973852431354060800/Yaer_iPhone.png"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973855715376631828/iPhone_pink.jpeg"/>
      </div>
      <div className='YAER'>
      <img className='img-YAER' src="https://media.discordapp.net/attachments/942837384855306280/973855716450385930/iPhone_color.jpeg"/>
      </div>
      </div>
    </div>
    </div>

  );
}

export default YAER;
