import React from 'react';
import PropTypes from 'prop-types';
import './Doggy.module.css';


Doggy.propTypes = {};

Doggy.defaultProps = {};

function Doggy(){
  return (
    <div className="Doggy">
      <div className="Doggy-img">
        <div className='Doggy-text-top'>Doggy-Top</div>
        <img src="https://cdn.discordapp.com/attachments/551380801913225217/957682305495875615/unknown.png" className='Doggy-text-bot'/>
        
        <img src="https://cdn.discordapp.com/attachments/482633647749595136/957381325520793640/unknown.png" className='Doggy-img' />
      </div>
    </div>
  );
}


export default Doggy;
