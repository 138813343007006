import React from 'react';
import PropTypes from 'prop-types';
import styles from './Calendar.module.css';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';


import "@fullcalendar/daygrid/main.css";



export default class Calendar extends React.Component {
  
render(){
  Calendar.defaultProps = {
    events:[{title:"F",start:"7-8-2022"}]
  };
  console.log(Calendar.events)

  

  return (
    <FullCalendar
    plugins={[ dayGridPlugin,bootstrap5Plugin,interactionPlugin,timeGridPlugin,listPlugin  ]}
    eventClick={this.handleEventClick}
    initialView="dayGridMonth"
    themeSystem= 'bootstrap5'
    headerToolbar={{
      left: "prev,next",
      center: "",
      right: "dayGridDay,dayGridWeek,dayGridMonth,listYear"
    }}
    buttonText={{
      list:"year"
    }}
    events={this.props.events}
    views= {{
      listAllYears: {
          type: 'listYear',
          duration: { years: 999 },
      },
  }}
  />
  )
}

handleEventClick = (arg) => { // bind with an arrow function
  alert(arg.event._def.title)
  console.log(arg.event._def.title)
}
  
};


/*

  */